import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import CreateGroup from "../CreateGroup";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useHistory } from "react-router-dom";
import NewTicketModal from "../NewTicketModal";

const TicketOptionsMenu = ({
  ticket,
  menuOpen,
  handleClose,
  anchorEl,
  createGroup,
}) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationBlock, setConfirmationBlock] = useState(false);
  const [confirmationBlockGroup, setConfirmationBlockGroup] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const isMounted = useRef(true);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleUnreadTicket = async () => {
    try {
      history.push("/tickets");
      await api.put(`/tickets/${ticket?.id}`, {
        status: "open",
        userId: null,
        tipo: "UnreadTicket",
        unreadMessages: 1,
        //queueId: null,
      });
    } catch (err) {
      toastError(err);
    }
    //history.push("/tickets");
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };

  const handleBlockConfirmationModal = (e) => {
    setConfirmationBlock(true);
    handleClose();
  };
  const handleBlockGroupConfirmationModal = (e) => {
    setConfirmationBlockGroup(true);
    handleClose();
  };

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket?.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleBlockContact = async () => {
    try {
      await api.post(`/contact/block/`, {
        user_id: user.id,
        id: ticket?.contactId,
        type: "system",
        ticketId: ticket?.id,
      });
      history.push("/tickets");
    } catch (err) {
      toastError(err);
    }
  };

  const handleBlockContactFull = async () => {
    try {
      await api.post(`/contact/block/`, {
        user_id: user.id,
        id: ticket?.contactId,
        type: "full",
        ticketId: ticket?.id,
      });
      history.push("/tickets");
    } catch (err) {
      toastError(err);
    }
  };
  const handleBlockGroupFull = async () => {
    try {
      await api.post(`/group/block/0`, {
        user_id: user.id,
        id: ticket?.contactId,
      });
      history.push("/tickets");
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenTransferModal = (e) => {
    setTransferTicketModalOpen(true);
    handleClose();
  };

  const handleCreteGroupOpen = (e) => {
    setCreateGroupOpen(true);
    handleClose();
  };

  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };

  const handleCreteGroupClose = () => {
    if (isMounted.current) {
      setCreateGroupOpen(false);
    }
  };

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {createGroup == "true" && (
          <MenuItem onClick={() => setNewTicketModalOpen(true)}>
            Criar Atendimento
          </MenuItem>
        )}
        {createGroup == "true" && (
          /* 
			<Can
						role={user.profile}
						perform="ticket-options:deleteTicket"
						yes={() => (
							<MenuItem onClick={handleCreteGroupOpen}>
								Criar Grupo
							</MenuItem>
						)}
					/>)}
			
			*/

          <MenuItem onClick={handleCreteGroupOpen}>Criar Grupo</MenuItem>
        )}

        {!createGroup && (
          <MenuItem onClick={handleOpenTransferModal}>
            {i18n.t("ticketOptionsMenu.transfer")}
          </MenuItem>
        )}
        {!createGroup && (
          <Can
            role={user.profile}
            perform="ticket-options:deleteTicket"
            yes={() => (
              <MenuItem onClick={handleOpenConfirmationModal}>
                {i18n.t("ticketOptionsMenu.delete")}
              </MenuItem>
            )}
          />
        )}
        {!createGroup && (
          <Can
            role={user.profile}
            perform="ticket-options:setUnreadTicket"
            yes={() => (
              <MenuItem onClick={handleUnreadTicket}>
                {"Marcar como não lido"}
              </MenuItem>
            )}
          />
        )}
      </Menu>
      <ConfirmationModal
        title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
          ticket?.id
        } ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
          ticket?.contact?.name
        }?`}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteTicket}
      >
        {i18n.t("ticketOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>

      <TransferTicketModal
        modalOpen={transferTicketModalOpen}
        onClose={handleCloseTransferTicketModal}
        ticketid={ticket?.id}
        ticketWhatsappId={ticket?.whatsappId}
      />

      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />

      <CreateGroup
        modalOpen={createGroupOpen}
        onClose={handleCreteGroupClose}
        ticketid={ticket?.id}
        ticketWhatsappId={ticket?.whatsappId}
      />
    </>
  );
};

export default TicketOptionsMenu;
